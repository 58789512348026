import styled from "styled-components";

export const FeaturedPostWrapper = styled.div`
  margin: auto;
  position: relative;
  width: 100%;
  .pagination {
    margin-top: 80px;
    @media (max-width: 1024px) {
      margin-top: 60px;
    }
    @media (max-width: 575px) {
      margin-top: 30px;
    }
  }
`;

export const FeaturedPostRow = styled.div`
  margin: 0 -15px;
  display: flex;
  flex-wrap: wrap;
  @media (max-width: 1024px) {
    margin: 0 -10px;
  }
  .swiper-container {
    width: 100%;
    padding: 15px;
    @media (max-width: 600px) {
      padding: 5px;
    }
  }
`;

export const FeaturedPostCol = styled.div`
  max-width: 20%;
  flex: 0 0 20%;
  float: left;
  padding: 0;
  @media (max-width: 1024px) {
    padding: 0 10px;
    max-width: 33.3333333%;
    flex: 0 0 33.3333333%;
    margin-bottom: 20px;
  }
  @media (max-width: 767px) {
    max-width: 100%;
    flex: 0 0 100%;
    margin-bottom: 0;
    padding: 10px;
  }
  }
`;
