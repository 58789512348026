import * as React from "react";
import { Link } from "gatsby";
import _ from "lodash";
import Img from "gatsby-image";
import {
  FeaturedCardWrapper,
  PostPreview,
  PostDetails,
  PostTitle,
} from "./featured-card.style";

interface FeaturedCardProps {
  image?: any;
  title: string;
  description?: string;
  url: string;
  tags?: [];
  className?: string;
  imageType?: "fixed" | "fluid";
  overlay?: boolean;
}

const FeaturedCard: React.FunctionComponent<FeaturedCardProps> = ({
  image,
  title,
  description,
  url,
  tags,
  className,
  imageType,
  overlay,
  ...props
}) => {
  const addClass = ["featured_card"];

  if (overlay == true) {
    addClass.push("overlay");
  }

  if (className) {
    addClass.push(className);
  }

  return (
    <FeaturedCardWrapper className={addClass.join(" ")} {...props}>
      {image == null ? null : (
        <PostPreview className="post_preview">
          <Link to={url}>
            {imageType === "fluid" ? (
              <Img fluid={image} alt="post preview" />
            ) : (
              <Img fixed={image} alt="post preview" />
            )}
          </Link>
        </PostPreview>
      )}

      <PostDetails className="post_details">
        <PostTitle className="post_title">
          <Link to={url}>{title}</Link>
        </PostTitle>
      </PostDetails>
    </FeaturedCardWrapper>
  );
};

FeaturedCard.defaultProps = {
  imageType: "fluid",
};

export default FeaturedCard;
