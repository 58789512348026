import React from "react";
import { VideoWrapper, VideoContainer } from "./style";
import Swiper from "react-id-swiper";

type VideoProps = {};

const Video: React.FunctionComponent<VideoProps> = () => {
  const params = {
    slidesPerView: "auto",
    centeredSlides: true,
    spaceBetween: 30,
    pagination: {
      el: ".swiper-pagination",
      type: "fraction",
    },
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
  };
  return (
    <VideoWrapper>
      <Swiper {...params}>
        <VideoContainer>
          <iframe
            src="https://www.facebook.com/plugins/video.php?href=https%3A%2F%2Fwww.facebook.com%2Fdjpaulperu%2Fvideos%2F1805208856404040%2F&show_text=0&width=560"
            width="100%"
            height="100%"
            scrolling="no"
            frameborder="0"
            allowTransparency="true"
            allowFullScreen="true"
            style={{
              position: "absolute",
              margin: "auto",
              top: "0",
              left: "0",
              right: "0",
              bottom: "0",
            }}
          ></iframe>
        </VideoContainer>
        <VideoContainer>
          <iframe
            src="https://www.facebook.com/plugins/video.php?href=https%3A%2F%2Fwww.facebook.com%2Fdjpaulperu%2Fvideos%2F515148545724835%2F&show_text=0&width=560"
            width="100%"
            height="100%"
            scrolling="no"
            frameborder="0"
            allowTransparency="true"
            allowFullScreen="true"
            style={{
              position: "absolute",
              margin: "auto",
              top: "0",
              left: "0",
              right: "0",
              bottom: "0",
            }}
          ></iframe>
        </VideoContainer>
        <VideoContainer>
          <iframe
            src="https://www.facebook.com/plugins/video.php?href=https%3A%2F%2Fwww.facebook.com%2Fdjpaulperu%2Fvideos%2F585267461963736%2F&show_text=0&width=560"
            width="100%"
            height="100%"
            scrolling="no"
            frameborder="0"
            allowTransparency="true"
            allowFullScreen="true"
            style={{
              position: "absolute",
              margin: "auto",
              top: "0",
              left: "0",
              right: "0",
              bottom: "0",
            }}
          ></iframe>
        </VideoContainer>
        <VideoContainer>
          <iframe
            src="https://www.facebook.com/plugins/video.php?href=https%3A%2F%2Fwww.facebook.com%2Fdjpaulperu%2Fvideos%2F1849105052014420%2F&show_text=0&width=560"
            width="100%"
            height="100%"
            scrolling="no"
            frameborder="0"
            allowTransparency="true"
            allowFullScreen="true"
            style={{
              position: "absolute",
              margin: "auto",
              top: "0",
              left: "0",
              right: "0",
              bottom: "0",
            }}
          ></iframe>
        </VideoContainer>
      </Swiper>
    </VideoWrapper>
  );
};

export default Video;
