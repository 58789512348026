import styled from "styled-components";
import { themeGet } from "@styled-system/theme-get";

export const FeaturedCardWrapper = styled.div`
  position: relative;
  .post_details {
    background: transparent;
    padding: 0;
  }
  .post_title {
    font-family: "Montserrat", sans-serif;
    text-transform: uppercase;
    line-height: 1.4;
    a {
      color: #292929;
    }
  }
`;

export const PostPreview = styled.div`
  position: relative;
  overflow: hidden;
  img {
    border-radius: 3px;
  }
`;

export const PostDetails = styled.div`
  position: relative;
  bottom: 0;
  padding: 15px;
  border-radius: 4px;
  transition: 0.3s ease-in-out;
`;

export const PostTitle = styled.h2`
  padding: 0;
  font-size: 21px;
  font-weight: 700;
  a {
    color: ${themeGet("colors.textColor", "#292929")};
  }
`;
