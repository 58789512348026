import * as React from "react";
import { useStaticQuery, graphql } from "gatsby";
import FeaturedCard from "../../../components/featured-card/featured-card";
import { FeaturedPostWrapper, FeaturedPostRow, FeaturedPostCol } from "./style";
import Swiper from "react-id-swiper";

type FeaturedPostsProps = {};

const FeaturedPosts: React.FunctionComponent<FeaturedPostsProps> = () => {
  const params = {
    slidesPerView: 6,
    spaceBetween: 5,
    pagination: {
      el: ".swiper-pagination",
      clickable: true,
    },
    breakpoints: {
      1024: {
        slidesPerView: 4,
        spaceBetween: 40,
      },
      768: {
        slidesPerView: 3,
        spaceBetween: 30,
      },
      640: {
        slidesPerView: 2,
        spaceBetween: 20,
      },
      320: {
        slidesPerView: 1,
        spaceBetween: 10,
      },
    },
  };
  const Data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
        }
      }
      allSitePage(filter: { path: { eq: "/page/1" } }) {
        nodes {
          context {
            numPages
            currentPage
          }
        }
      }
      allMarkdownRemark(
        filter: { frontmatter: { tags: { eq: "discoteca" } } }
        sort: { fields: [frontmatter___date], order: DESC }
        limit: 6
      ) {
        totalCount
        edges {
          node {
            excerpt(pruneLength: 150)
            fields {
              slug
            }
            frontmatter {
              date(formatString: "MMM DD, YYYY")
              title
              description
              tags
              cover {
                childImageSharp {
                  fluid(maxWidth: 200) {
                    ...GatsbyImageSharpFluid_withWebp_tracedSVG
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  const Posts = Data.allMarkdownRemark.edges;

  return (
    <FeaturedPostWrapper>
      <FeaturedPostRow>
        <Swiper {...params}>
          {Posts.map(({ node }: any) => {
            const title = node.frontmatter.title || node.fields.slug;
            return (
              <FeaturedPostCol key={title}>
                <FeaturedCard
                  title={title}
                  image={
                    node.frontmatter.cover == null
                      ? null
                      : node.frontmatter.cover.childImageSharp.fluid
                  }
                  url={"/musica/" + node.fields.slug}
                  tags={node.frontmatter.tags}
                  description={node.excerpt}
                  overlay={true}
                />
              </FeaturedPostCol>
            );
          })}
        </Swiper>
      </FeaturedPostRow>
    </FeaturedPostWrapper>
  );
};

export default FeaturedPosts;
