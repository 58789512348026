import * as React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Image from "gatsby-image";
import FeaturedPosts from "./featured-post";
import SimpleSwiper from "./post-slider";
import Timeline from "./timeline";
import Fade from "react-reveal/Fade";
import Video from "./video";
import Zigzag from "../../components/zigzag/zigzag";
import {
  AboutWrapper,
  AboutImage,
  AboutPageTitle,
  AboutPageSubTitle,
  AboutDetails,
  FeaturedPostsContainer,
} from "./style";

interface AboutProps {}

const About: React.FunctionComponent<AboutProps> = () => {
  const Data = useStaticQuery(graphql`
    query {
      avatar: file(absolutePath: { regex: "/dj-paul-about.jpg/" }) {
        childImageSharp {
          fluid(maxWidth: 1770) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      site {
        siteMetadata {
          author
          about
        }
      }
    }
  `);

  return (
    <AboutWrapper>
      <AboutImage>
        <Image fluid={Data.avatar.childImageSharp.fluid} alt="author" />
      </AboutImage>
      <Fade right>
        <AboutPageTitle>residencias</AboutPageTitle>
        <AboutPageSubTitle>hasta que llegó la pandemia</AboutPageSubTitle>
      </Fade>
      <FeaturedPostsContainer>
        <FeaturedPosts />
      </FeaturedPostsContainer>
      <Fade right>
        <AboutPageTitle>en acción</AboutPageTitle>
        <AboutPageSubTitle>
          Dj Paul tocando antes de la pandemia. 🔥
        </AboutPageSubTitle>
      </Fade>
      <Video />
      <Zigzag />
      <Fade right>
        <AboutPageTitle>producción</AboutPageTitle>
        <AboutPageSubTitle>
          las canciones que salieron del estudio de Dj Paul
        </AboutPageSubTitle>
      </Fade>
      <Fade left>
        <AboutDetails>
          <div class="column">
            <p>
              Con lanzamientos de de temas que traen un nivel de calidad que se
              deja sentir. Dj Paul trabaja continuamente en el estudio,
              produciendo música que funcione en la pista de baile, habiendo
              trabajado con artistas internacionales como Donny Caballero de
              Colombia, en la canción "La Alergia" que es hoy en día un clásico
              mainstream en todas las fiestas. Con un background de años en la
              post-producción de sonido desde muy temprana edad, Protools ha
              sido la herramienta del día a día por unos 20 años ya. Trabajando
              de la mano con el productor Juan Carlos Fernández, aprendiendo
              independientemente y siguiendo el camino en la carrera de
              productor musical. Actualmente, Dj Paul estaba produciendo un
              álbum completo para una talentosa cantante argentina, con géneros
              como trap, reggaetón, urbano y dembow dominicano, hasta que llegó
              la pandemia.
            </p>
          </div>
        </AboutDetails>
      </Fade>
      <SimpleSwiper />

      <Fade right>
        <AboutPageTitle>la historia</AboutPageTitle>
        <AboutPageSubTitle>
          desde los inicios hasta la actualidad
        </AboutPageSubTitle>
      </Fade>
      <Fade left>
        <AboutDetails>
          <div class="column">
            <p>
              Los 2000 fueron una década que tuvo gran repercusión en la música
              de fiesta, con la “La Gasolina” de Daddy Yankee habiendo llegado a
              cada rincón del planeta, la transición a tener un género
              predominante era ineludible con la rápida proliferación del
              reggaetón, esto cambió la propuesta musical nocturna a como la
              conocemos hoy en día.
            </p>
            <p>
              Fue en el 2004 que Dj Paul (Paul Sutcliffe) empieza su carrera de
              Dj en la entonces exclusiva discoteca “SPA” en San Isidro;
              manejando a un público mayor y selecto. Con un planteamiento
              musical muy primoroso, teniendo como base la música Disco, rock de
              los 80’s, Pop en inglés y Latin-Pop del momento.
            </p>
            <p>
              Con el mismo público durante el verano del 2006 Dj Paul tiene la
              residencia de “Zsa Zsa”, en Asia. Para dar el gran salto a
              “Nikita” en el verano del 2007, la emblemática discoteca del
              boulevard de Asia, con una residencia de 5 años en la que se rigió
              por una convocatoria de gente mayor, con la parte mainstream de la
              música actual y una buena cuota de 80’s.
            </p>
            <p>
              En el invierno del 2009 y 2010 “Ananda”, el 2011 inició con un
              verano diferente, un público más joven, en “Joia” con unos after
              party hasta las 8 de la mañana y en el invierno la casa fue
              “Bizarro” en Miraflores.
            </p>
            <p>
              En el verano del 2012 abrió “Sweet Pacha” en Asia donde Dj Paul
              trabajó un estilo freestyle de Funk, RnB, Breaks, Disco. Ese mismo
              verano también tuvo la residencia en “Rumba La Huaka” los viernes
              donde era una noche de pachanga en la legendaria discoteca para
              los más chicos del boulevard, con lo último de la pachanga y el
              reggaetón.
            </p>
            <p>
              El 2014 fue el año que Lima tuvo un cambio en la movida fiestera,
              abre el “City Walk” de Chacarilla, con una promesa fuerte del
              mejor entretenimiento, varios locales para todos los gustos y
              edades en el mismo lugar. La discoteca más amplia y elaborada
              “Mia” fue el escenario de una residencia muy intensa.
            </p>

            <p>
              2015 representó una mudanza, todos los sábados de invierno en
              “Dalí” en Barranco, con una abundante concurrencia, cientos de
              personas en la puerta sin poder entrar por el aforo cada fin de
              semana, sin dudas la propuesta más sobresaliente para la juerga
              limeña. Los miércoles en “La Fiesta de Todos Los Mundos” en
              “Bizarro”, y los jueves en “Mute” en Chacarilla.
            </p>
          </div>
          <div class="column">
            <p>
              Para el 2016 el panorama se veía aún mejor, “Resident” en Punta
              Negra empezando en diciembre y todo el verano hasta semana santa y
              continuando con la misma familia en “Dalí” para el invierno. Los
              jueves en un nuevo local, “Catalina Bar” al que le suelen llamar
              “El Taller” con un cóctel musical recorriendo todos los géneros
              alternativos desde Funk, Drum and Bass, RnB, Breaks, y con un
              porción de EDM, reggaetón y hits del momento dando resultado una
              aleación de especies bastante simpática.
            </p>
            <p>
              En el 2017 Dj Paul empieza el año con una residencia los martes de
              verano en “Ninna” en Chacarilla, con un común denominador súper
              joven, donde predomina el reggaetón actual, el ancestral y el
              trap. Los miércoles en “Bizarro TM”, los jueves en “Catalina Bar”,
              los viernes en “Noise” y los sábados en “Resident”
            </p>
            <p>
              El 2018 seguimos con los miércoles en “Bizarro” con “La Fiesta de
              Todos los Mundos” con un repertorio más internacional, bastante
              música en inglés para complacer al aforo extranjero. Con el mejor
              Sound System de la ciudad Lyra todos los viernes para un público
              VIP y los viernes de verano en Amadeus Asia. Los sábados en
              matrimonios y Dalí durante invierno y Resident en el Verano.
            </p>
            <p>
              A mediados del 2018 empezando desde su primera fecha, una nueva
              residencia todos los jueves en Barranco “Loola”, hasta la fecha lo
              mejor de los jueves.
            </p>
            <p>
              En el 2019 iniciando la temporada de invierno con una nueva
              residencia para los sábados, un giro en la vida nocturna limeña
              bautizado como “Qiu” y de regreso un viernes al mes en el templo
              del reggatón “Cochinola”
            </p>
          </div>
        </AboutDetails>
      </Fade>
      <Timeline />
    </AboutWrapper>
  );
};

export default About;
