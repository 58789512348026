import styled from "styled-components";

export const AboutWrapper = styled.div``;

export const AboutPageTitle = styled.h2``;

export const AboutPageSubTitle = styled.h3``;

export const AboutImage = styled.div``;

export const AboutDetails = styled.div`
  width: 60%;
  margin: 0 auto 90px;
  column-count: 2;
  @media only screen and (max-width: 600px) {
    column-count: 1;
    width: 92%;
    margin: 20px auto 20px;
  }
`;

export const FeaturedPostsContainer = styled.div`
  padding: 0 75px;
`;
