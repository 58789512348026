import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import About from "../containers/dj-paul";

type AboutPageProps = {};

const AboutPage: React.FunctionComponent<AboutPageProps> = (props) => {
  return (
    <Layout>
      <SEO
        title="Residencias, Producción, Historia"
        description="Dj Paul recorre el Perú presentándose en los eventos y discotecas más importantes a la par ha sido y es residente de las mejores y más exclusivas discotecas de Lima."
      />

      <About />
    </Layout>
  );
};

export default AboutPage;
