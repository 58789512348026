import React from "react";
import TimelineWrapper from "./style.tsx";
import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";

type TimelineProps = {};

const Timeline: React.FunctionComponent<TimelineProps> = () => {
  const TimelinePictures = useStaticQuery(graphql`
    query {
      allFile(
        sort: { fields: name, order: ASC }
        filter: { relativeDirectory: { eq: "dj-paul-timeline" } }
      ) {
        edges {
          node {
            name
            relativePath
            childImageSharp {
              fluid(maxWidth: 960) {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
              }
            }
          }
        }
      }
    }
  `);
  return (
    <TimelineWrapper>
      <div class="flex-parent">
        <div class="input-flex-container">
          <input
            type="radio"
            name="timeline-dot"
            data-description="2004"
          ></input>
          <div class="dot-info" data-description="2004">
            <span class="year">2004</span>
            <span class="label">SPA</span>
          </div>
          <input
            type="radio"
            name="timeline-dot"
            data-description="2007"
          ></input>
          <div class="dot-info" data-description="2007">
            <span class="year">2007</span>
            <span class="label">Nikita</span>
          </div>
          <input
            type="radio"
            name="timeline-dot"
            data-description="2011"
            checked
          ></input>
          <div class="dot-info" data-description="2011">
            <span class="year">2011</span>
            <span class="label">Joia</span>
          </div>
          <input
            type="radio"
            name="timeline-dot"
            data-description="2011b"
          ></input>
          <div class="dot-info" data-description="2011b">
            <span class="year">2011</span>
            <span class="label">Bizarro</span>
          </div>
          <input
            type="radio"
            name="timeline-dot"
            data-description="2014"
          ></input>
          <div class="dot-info" data-description="2014">
            <span class="year">2014</span>
            <span class="label">Mia</span>
          </div>
          <input
            type="radio"
            name="timeline-dot"
            data-description="2015"
          ></input>
          <div class="dot-info" data-description="2015">
            <span class="year">2015</span>
            <span class="label">Dalí</span>
          </div>
          <input
            type="radio"
            name="timeline-dot"
            data-description="2015b"
          ></input>
          <div class="dot-info" data-description="2015b">
            <span class="year">2015</span>
            <span class="label">Mute</span>
          </div>
          <input
            type="radio"
            name="timeline-dot"
            data-description="2016"
          ></input>
          <div class="dot-info" data-description="2016">
            <span class="year">2016</span>
            <span class="label">Resident</span>
          </div>
          <input
            type="radio"
            name="timeline-dot"
            data-description="2016b"
          ></input>
          <div class="dot-info" data-description="2016b">
            <span class="year">2016</span>
            <span class="label">Cochinola</span>
          </div>
          <input
            type="radio"
            name="timeline-dot"
            data-description="2018"
          ></input>
          <div class="dot-info" data-description="2018">
            <span class="year">2018</span>
            <span class="label">Loola</span>
          </div>
          <input
            type="radio"
            name="timeline-dot"
            data-description="2019"
          ></input>
          <div class="dot-info" data-description="2019">
            <span class="year">2019</span>
            <span class="label">Qiu</span>
          </div>
          <div id="timeline-descriptions-wrapper">
            <p data-description="2004"></p>
            <p data-description="2007">
              <Img
                fluid={
                  TimelinePictures.allFile.edges[0].node.childImageSharp.fluid
                }
              />
            </p>
            <p data-description="2011">
              <Img
                fluid={
                  TimelinePictures.allFile.edges[1].node.childImageSharp.fluid
                }
              />
            </p>
            <p data-description="2011b">
              <Img
                fluid={
                  TimelinePictures.allFile.edges[2].node.childImageSharp.fluid
                }
              />
            </p>
            <p data-description="2014">
              <Img
                fluid={
                  TimelinePictures.allFile.edges[3].node.childImageSharp.fluid
                }
              />
            </p>
            <p data-description="2015">
              <Img
                fluid={
                  TimelinePictures.allFile.edges[4].node.childImageSharp.fluid
                }
              />
            </p>
            <p data-description="2015b">
              <Img
                fluid={
                  TimelinePictures.allFile.edges[5].node.childImageSharp.fluid
                }
              />
            </p>
            <p data-description="2016">
              <Img
                fluid={
                  TimelinePictures.allFile.edges[6].node.childImageSharp.fluid
                }
              />
            </p>
            <p data-description="2016b">
              <Img
                fluid={
                  TimelinePictures.allFile.edges[7].node.childImageSharp.fluid
                }
              />
            </p>
            <p data-description="2018">
              <Img
                fluid={
                  TimelinePictures.allFile.edges[8].node.childImageSharp.fluid
                }
              />
            </p>
            <p data-description="2019">
              <Img
                fluid={
                  TimelinePictures.allFile.edges[9].node.childImageSharp.fluid
                }
              />
            </p>
          </div>
        </div>
      </div>
    </TimelineWrapper>
  );
};

export default Timeline;
